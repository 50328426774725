import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nie znaleziono" />
    <div
      style={{ textAlign: "center", paddingTop: "30vh", paddingBottom: "40vh" }}
    >
      <h1>Nie ma!</h1>
      <p>Taka strona niestety nie istnieje.</p>
      <p>
        <Link to="/">Przejdź do strony głównej</Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
